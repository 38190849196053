<template>
<div>
  <div class="users scrollable-x mb-3">
    <div class="d-flex">
      <h1 style="display: inline;" class="mr-4">Users</h1>
      <div class="align-self-center">
        <select @change="updateUserFilter">
          <option value="recent">Recent</option>
          <option value="unarchived">Unarchived</option>
          <option value="onlineonly">Online Only</option>
          <option value="archived">Archived</option>
          <option value="test">Test Users</option>
          <option value="moderators">Moderators</option>
          <option value="all">All</option>
        </select>
      </div>
      <div class="ml-3 align-self-center" v-if="$debug.isDeveloperOn">
        <a target="_blank" 
          :href="'/tests/multi-guests?users=' + listFilteredUsers">
          Open guests in MultiGuests page🔗
        </a>
      </div>
    </div>
    <div class="d-flex" v-if="filteredUsers.length > 0">
      <div class="user" v-for="user in filteredUsers" v-bind:key="user.id">
        <user-view class="mr-1"
          v-bind:user="user"
        />
        <div class="d-flex flex-column mt-1 mb-2" v-if="manager">
          <div class="form-inline">
            <b-form-checkbox :checked="user.canCreateEvents" @change="(value) => updateUser(user.id, {canCreateEvents:value})">
            Can Create Events
            </b-form-checkbox>
          </div>
          <button class="btn btn-warning m-1"
            v-for="eid in manager.activeEvents" v-bind:key="eid"
            @click="addUsertoEventId(user.id, eid)">
            Add to event: {{ eid }}
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      <p>No users online</p>
    </div>
  </div>
  <div class="alert-info" v-if="$debug.isDeveloperOn">
    <div class="form-inline sub-mr-2">
      <div>Number of Test Users</div>
      <b-input v-model="numTestUsers"/>
      <button class="btn btn-primary" @click="createTestAccounts">Create Test Accounts</button>
      <button class="btn btn-outline-danger" @click="deleteTestUsers">Delete Test Accounts</button>
    </div>
    <div class="form-inline sub-mr-2">
      <div>Add Test Users to</div>
      <b-form-select :options="events" v-model="selectEvent" value-field="id" text-field="title"/>
      <button class="btn btn-primary" @click="addTestAccountsToEvent">Add Test Accounts to Event</button>
      <button class="btn btn-outline-danger" @click="removeTestAccountsFromEvent">Remove Test Accounts From Event</button>
    </div>
  </div>
</div>
</template>

<script>
import { db, serverTimestamp } from '@/services/db';
import user from "./User";
import { addUsertoEventId, updateUser } from "@/services/user";
import { getLog } from "@/services/log";
import { epoch } from '@/services/utils';
let log = getLog('admin-users');

export default {
  name: 'admin-users',
  components: {
    userView: user,
  },
  props: {
    manager: Object,
  },
  data() {
    return {
      users: [],
      usersFilter: "recent",
      filteredUsers: [],
      roomMatches: {},
      numTestUsers: 100,
      events: [],
      selectEvent: null,
    };
  },
  computed: {
    participatingUsers() {
      return this.users.filter(u => !u.archived && !u.moderator);
    },
    eventModerators() {
      return this.users.filter(u => u.moderator);
    },
    listFilteredUsers() {
      return JSON.stringify(this.filteredUsers.map((u) => { return {id: u.id, name: u.name}; }));
    },
    testUsersOnly() {
      return [];
    },
  },
  watch: {
    users() {
      if (!this.users)
        return;
      this.updateUserFilter();
    },
  },
  mounted() {
    log.log("mounted()");
    this.init();
  },
  methods: {
    async init() {
      let users = await this.$bind("users", db.collection("LiveUsers").orderBy("createdAt"));
      log.log(`Found ${users.length} user`);
      this.updateUserFilter();
      this.$bind("events", db.collection("LiveEvents"));
    },
    addUsertoEventId,
    updateUser,
    updateUserFilter(event) {
      let filter = event ? event.target.value : this.usersFilter;
      log.log("updateUserFilter", filter);
      let yesterday = epoch() - 24 * 3600;
      this.usersFilter = filter;
      if (filter == "unarchived")
        this.filteredUsers = this.users.filter(u => !u.archived);
      else if (filter == "onlineonly")
        this.filteredUsers = this.users.filter(u => u.online);
      else if (filter == "archived")
        this.filteredUsers = this.users.filter(u => u.archived);
      else if (filter == "moderators")
        this.filteredUsers = this.users.filter(u => u.moderator);
      else if (filter == "test")
        this.filteredUsers = this.users.filter(u => u.test);
      else if (filter == "recent")
        this.filteredUsers = this.users.filter(u => u.createdAt?.seconds > yesterday);
      else
        this.filteredUsers = this.users.filter(u => !u.archived && !u.moderator);
    },
    createTestAccounts() {
      let batch = db.batch();
      for (let i = 0; i < this.numTestUsers; ++i)
        batch.set(db.doc(`LiveUsers/testuser${i + 1}`), {
          name: `Test User ${i + 1}`,
          test: true,
          createdAt: serverTimestamp(),
          picture0: `https://ui-avatars.com/api/?size=512&name=${i + 1}`,
          waiverSigned: true,
        });
      batch.commit();
    },
    deleteTestUsers() {
      let batch = db.batch();
      for (let i = 0; i < this.numTestUsers; ++i)
        batch.delete(db.doc(`LiveUsers/testuser${i + 1}`));
      batch.commit();
    },
    addTestAccountsToEvent() {
      let userIds = this.events.find((e) => e.id == this.selectEvent).userIds;
      log.log("userIds", userIds);
      for (let i = 0; i < this.numTestUsers; ++i) {
        let id = `testuser${i + 1}`;
        if (!userIds.includes(id))
          userIds.push(id);
      }
      db.doc(`LiveEvents/${this.selectEvent}`).update({userIds});
    },
    removeTestAccountsFromEvent() {
      let userIds = this.events.find((e) => e.id == this.selectEvent).userIds;
      userIds = userIds.filter((u) => !u.startsWith("testuser"));
      log.log("userIds", userIds);
      db.doc(`LiveEvents/${this.selectEvent}`).update({userIds});
    }
  }
};
</script>

<style scoped>

.scrollable-x {
  overflow: auto;
  overflow-x: scroll;
  width: calc(100vw - 200px);
}

</style>